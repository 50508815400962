import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as css from "../styles/Home.module.scss";
import * as cssDirectors from "../styles/Directors.module.scss";

import { andList, classs } from "../utils/utils";
import Slideshow from "../components/Slideshow";
import Slider from "../components/Slider";
import ButtonBase from "../components/ButtonBase";
import ArticleButton from "../components/ArticleButton";
import CreationButton from "../components/CreationButton";

import I18n, { LocaleLink } from "../components/I18n";

const Articles = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: {
    strapi: {
      articles: { data: articles = [] } = {},
      slides: { data: slides = [] } = {},
      directors: { data: directors = [] } = {},
      artists: { data: artists = [] } = {},
      films: { data: films = [] } = {},
      productions: { data: productions = [] } = {},
      netarts: { data: netarts = [] } = {},
    } = {},
  },
}) => {
  return (
    <Layout
      pathname={pathname}
      className={css.Home}
      locale={locale}
      locales={locales}
    >
      {slides?.filter(({ attributes: { image } }) => image)?.length > 0 && (
        <Slideshow
          className="slideshow"
          num={slides.filter(({ attributes: { image } }) => image).length}
        >
          {slides
            .map(
              ({ attributes: { image, creation }, attributes, ...data }) => ({
                ...data,
                attributes,
                imageFileS3:
                  image?.data?.attributes?.fileS3 ??
                  creation?.data?.attributes?.image?.data?.attributes?.fileS3,
              })
            )
            .filter(({ imageFileS3 }) => imageFileS3)
            .map(
              (
                {
                  id,
                  imageFileS3,
                  attributes: { title, creation, description, link },
                },
                i
              ) => (
                <ButtonBase
                  component={link ? "a" : LocaleLink}
                  key={id}
                  style={{ "--item-n": i }}
                  {...{
                    [link ? "href" : "to"]:
                      link ??
                      (creation?.data?.attributes?.slug
                        ? creation?.data?.attributes?.productor === "balthuslab"
                          ? `/artnum/${creation?.data?.attributes?.slug}`
                          : `/film/${creation?.data?.attributes?.slug}`
                        : null),
                    target: link ? "_blank" : undefined,
                  }}
                >
                  <GatsbyImage
                    image={getImage(imageFileS3)}
                    objectFit="cover"
                    alt=""
                  />
                  <div>
                    <h2>{creation?.data?.attributes?.title ?? title}</h2>
                    <p>
                      {andList(
                        creation?.data?.attributes?.directors?.data?.map(
                          ({ attributes: { firstName, lastName } }) =>
                            [firstName, lastName].join(" ")
                        ),
                        "de"
                      ) ?? description}
                    </p>
                  </div>
                </ButtonBase>
              )
            )}
        </Slideshow>
      )}
      {articles?.length > 0 && (
        <section>
          <ButtonBase component={LocaleLink} to="/actualite" className="title">
            <I18n id="title.news" component="h1" />
          </ButtonBase>
          <Slider size="30rem">
            {articles.map(({ id, attributes }) => (
              <ArticleButton key={id} {...attributes} />
            ))}
          </Slider>
        </section>
      )}
      {(films?.filter(({ attributes: { poster } }) => poster)?.length > 0 ||
        productions?.filter(({ attributes: { image } }) => image)?.length > 0 ||
        directors?.filter(({ attributes: { image } }) => image)?.length >
          0) && (
        <section>
          {films?.filter(({ attributes: { poster } }) => poster)?.length >
            0 && (
            <>
              <ButtonBase component={LocaleLink} to="/film" className="title">
                <I18n id="title.films" component="h1" />
              </ButtonBase>
              <Slider size="20rem" className="films">
                {films
                  .filter(({ attributes: { poster } }) => poster)
                  .map(({ id, attributes }) => (
                    <CreationButton variant="poster" key={id} {...attributes} />
                  ))}
              </Slider>
            </>
          )}
          {productions?.filter(({ attributes: { image } }) => image)?.length >
            0 && (
            <>
              <ButtonBase component={LocaleLink} to="/film" className="title">
                <I18n id="title.films.status.production" component="h2" />
              </ButtonBase>
              <Slider size="35rem">
                {productions
                  .filter(({ attributes: { image } }) => image)
                  .map(({ id, attributes }) => (
                    <CreationButton key={id} {...attributes} />
                  ))}
              </Slider>
            </>
          )}
          {directors?.filter(({ attributes: { image } }) => image)?.length >
            0 && (
            <>
              <ButtonBase
                component={LocaleLink}
                to="/realisateur"
                className="title"
              >
                <I18n id="title.directors" component="h2" />
              </ButtonBase>
              <Slider size="20rem">
                {directors
                  .filter(({ attributes: { image } }) => image)
                  .map(
                    ({
                      id,
                      attributes: { slug, image, firstName, lastName },
                    }) => (
                      <ButtonBase
                        component={LocaleLink}
                        to={`/personne/${slug}`}
                        className={cssDirectors.Director}
                        key={id}
                      >
                        <GatsbyImage
                          image={getImage(image?.data?.attributes?.fileS3)}
                          alt=""
                        />
                        <p>
                          {[firstName, lastName].filter((v) => v).join(" ")}
                        </p>
                      </ButtonBase>
                    )
                  )}
              </Slider>
            </>
          )}
        </section>
      )}
      {(netarts?.filter(({ image }) => image)?.length > 0 ||
        artists?.filter(({ image }) => image)?.length > 0) && (
        <section>
          {netarts?.filter(({ image }) => image)?.length > 0 && (
            <>
              <ButtonBase component={LocaleLink} to="/artnum" className="title">
                <I18n id="title.netarts" component="h1" />
              </ButtonBase>
              <Slider size="35rem">
                {netarts
                  .filter(({ image }) => image)
                  .map(({ id, slug, ...props }) => (
                    <CreationButton
                      key={id}
                      to={`/artnum/${slug}`}
                      {...props}
                      watermark
                    />
                  ))}
              </Slider>
            </>
          )}
          {artists?.filter(({ image }) => image)?.length > 0 && (
            <>
              <ButtonBase component={LocaleLink} to="/artnum" className="title">
                <I18n id="title.netarts.artists" component="h2" />
              </ButtonBase>
              <Slider size="20rem">
                {artists
                  .filter(({ image }) => image)
                  .map(({ id, slug, image, firstName, lastName }) => (
                    <ButtonBase
                      component={LocaleLink}
                      key={id}
                      to={`/personne/${slug}`}
                      className={classs(cssDirectors.Director)}
                    >
                      <GatsbyImage image={getImage(image?.fileS3)} alt="" />
                      <p>{[firstName, lastName].filter((v) => v).join(" ")}</p>
                    </ButtonBase>
                  ))}
              </Slider>
            </>
          )}
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($locale: Strapi_I18NLocaleCode!) {
    strapi {
      articles(sort: "publication_date:desc", pagination: { limit: 20 }) {
        data {
          id
          attributes {
            title
            description
            source
            url: link
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      directors: artists(
        locale: $locale
        filters: { creations: { productor: { eq: "pelleas" } } }
        pagination: { limit: 20 }
      ) {
        data {
          id
          attributes {
            slug
            # triName: tri_name
            firstName: first_name
            lastName: last_name
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      artists(
        locale: $locale
        filters: { creations: { productor: { eq: "balthuslab" } } }
        pagination: { limit: 20 }
      ) {
        data {
          id
          attributes {
            slug
            firstName: first_name
            lastName: last_name
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      slides(locale: $locale) {
        data {
          id
          attributes {
            title
            description
            link
            creation {
              data {
                id
                attributes {
                  slug
                  productor
                  title
                  color
                  directors {
                    data {
                      id
                      attributes {
                        firstName: first_name
                        lastName: last_name
                      }
                    }
                  }
                  image {
                    data {
                      id
                      attributes {
                        url
                        formats
                        fileS3(minFormat: "xlarge") {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1.5625
                              quality: 90
                              layout: FULL_WIDTH
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "xlarge") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5625
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      films: creations(
        locale: $locale
        sort: "release_date:desc"
        filters: {
          productor: { eq: "pelleas" }
          status: { eq: "ready" }
          slug: { notNull: true }
        }
      ) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            title
            color
            productor
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            poster {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 0.7261
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      productions: creations(
        locale: $locale
        sort: "release_date:desc"
        filters: {
          productor: { eq: "pelleas" }
          status: { eq: "production" }
          slug: { notNull: true }
        }
      ) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            title
            color
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5625
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      netarts: creations(
        locale: $locale
        sort: "release_date:desc"
        filters: { productor: { eq: "balthuslab" }, slug: { notNull: true } }
      ) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            title
            color
            productor
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5625
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Articles;
