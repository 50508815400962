import React from "react";
import * as css from "../styles/Slider.module.scss";
import { classs } from "../utils/utils.js";
import useDragScroll from "../utils/useDragScroll.js";
import * as cssButton from "../styles/ButtonBase.module.scss";
import ButtonBase from "../components/ButtonBase";
import ArrowRight from "@mdi/svg/svg/arrow-right-circle.svg";

export default ({ className, children, size, minCount = 0 }) => {
  const { ref } = useDragScroll();

  const to = (dir) => () =>
    ref.current.scroll({
      left: ref.current.scrollLeft + 600 * dir,
      behavior: "smooth",
    });

  return (
    <div
      className={classs(css.Slider, className)}
      style={{
        "--items-count": Math.max(children?.length, minCount),
        ...(size ? { "--item-size": size } : {}),
      }}
    >
      <ul ref={ref}>{children}</ul>
      <div className="controls">
        <ButtonBase className={cssButton.IconButton} onClick={to(-1)}>
          <ArrowRight
            style={{ transform: "rotate(180deg)", marginRight: "4px" }}
          />
        </ButtonBase>
        <ButtonBase className={cssButton.IconButton} onClick={to(+1)}>
          <ArrowRight />
        </ButtonBase>
      </div>
    </div>
  );
};
